<script src="http://res.wx.qq.com/open/js/jweixin-1.6.0.js"></script>
<template>
  <el-form
      class="login-form"
      status-icon
      :rules="loginRules"
      ref="loginForm"
      :model="loginForm"
      label-width="0">
    <el-form-item prop="username">
      <el-input
          size="small"
          @keyup.enter.native="handleLogin"
          v-model="loginForm.username"
          auto-complete="off"
          placeholder="请输入账号">
        <i
            slot="prefix"
            class="icon-yonghu"></i>
      </el-input>
    </el-form-item>
    <el-form-item prop="password">
      <el-input
          size="small"
          @keyup.enter.native="handleLogin"
          :type="passwordType"
          v-model="loginForm.password"
          auto-complete="off"
          placeholder="密码">
        <i
            class="el-icon-view el-input__icon"
            slot="suffix"
            @click="showPassword"></i>
        <i
            slot="prefix"
            class="icon-mima"></i>
      </el-input>
    </el-form-item>
    <el-form-item>
      <el-button
          type="primary"
          size="small"
          @click.native.prevent="handleLogin"
          class="login-submit">登录
      </el-button>
    </el-form-item>
  </el-form>
</template>
<script>

import {
  getList
} from '@/api/health/test'
import { selectOne } from '@/api/sys/user'

export default {
  name: 'UserLogin',
  loginuser: '',
  setloginuser(loginuser) {
    this.loginuser = loginuser;
  },
  data () {
    return {
      loginForm: {
        username: '',
        password: '',
        requestHeadUrl: 'https://dbadmin.dbzdjk.com',
        type: false,
        appLink: ''
      },
      loginRules: {
        username: [
          { required: true, message: '请输入用户名', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { min: 5, message: '密码长度最少为5位', trigger: 'blur' }
        ]
      },
      passwordType: 'password',
      params: {
        username: ''
      }
    }
  },
  created () {
  },
  methods: {
    showPassword () {
      this.passwordType === ''
        ? (this.passwordType = 'password')
        : (this.passwordType = '')
    },
    handleLogin () {
      this.$refs.loginForm.validate(valid => {
        if (valid) {
          this.$store.dispatch('LoginByUsername', this.loginForm).then(() => {
            // this.$router.push({ path: '/' })
            this.loginuser = this.loginForm.username
            this.$myUser.setloginuser(this.loginForm.username);
            window.localStorage.setItem('username', this.loginForm.username)
            this.params.username = this.$myUser.loginuser
            selectOne(this.params).then(res => {
              window.localStorage.setItem('channel', res.data.channel)
            }).then(res => {
              this.$router.push({ path: '/selectInstitution' })
            })
          })
        }
      })
    },
    onLoad () {
      getList().then(res => {
        console.log(res)
        this.appLink = res.data
        location.href=res.data
      })
    }
  }
}
</script>

<style>
</style>
